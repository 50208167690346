import { Shift, WeekShifts, ShiftsNamesCount } from '../../types/Shift';
import { SelectOption } from '../../types/Shared';
import dateUtils from './date';

export interface ShiftSelectOptions extends Omit<SelectOption, 'label'> {
  startTime: string;
  endTime: string;
  label: string;
}

const HOUR_FORMAT_12 = 'hh:mm a';
const HOUR_FORMAT_24 = 'HH:mm';

const getShiftsForSelect = (
  date: string,
  shifts: Shift[],
  hours12: boolean,
): ShiftSelectOptions[] => {
  return shifts.map((shift) => {
    const startTime = dateUtils
      .getDate(`${date} ${shift.start_hour}`, 'YYYY-MM-DD HH:mm')
      .format(hours12 ? HOUR_FORMAT_12 : HOUR_FORMAT_24);
    const endTime = dateUtils
      .getDate(`${date} ${shift.end_hour}`, 'YYYY-MM-DD HH:mm')
      .format(hours12 ? HOUR_FORMAT_12 : HOUR_FORMAT_24);

    return {
      label: shift.name,
      value: String(shift.id),
      startTime,
      endTime,
    };
  });
};

export const sortShifts: (shifts: WeekShifts[]) => WeekShifts[] = (shifts: WeekShifts[]) => {
  shifts.forEach((day) => {
    day.shifts.sort((shift1, shift2) => {
      if (shift1.start_hour < shift2.start_hour) return -1;
      if (shift1.start_hour > shift2.start_hour) return 1;
      return shift1.name.localeCompare(shift2.name); // Compare by name if start hours are equal
    });
  });
  return shifts;
};

export const getWeekShiftNamesCount: (shifts: WeekShifts[]) => ShiftsNamesCount = (
  shifts: WeekShifts[],
) => {
  const counter: ShiftsNamesCount = {};
  shifts.forEach((day) => {
    day.shifts.forEach((shift) => {
      const { name } = shift;
      if (!counter[name]?.length) counter[name] = [day.weekday];
      else counter[name].push(day.weekday);
    });
  });
  return counter;
};

type DefaultShift = {
  start_hour: 'string';
  end_hour: 'string';
};

type NormalShift = {
  start_time: 'string';
  end_time: 'string';
};

const MIN_IN_HOUR = 60;

export const getShiftWorkingHours: (
  shift:
    | { start_time: string; end_time: string; break_time?: number | null }
    | { start_hour: string; end_hour: 'string'; break_time?: number | null },
) => number = (shift) => {
  const start = (shift as NormalShift).start_time || (shift as DefaultShift).start_hour;
  const end = (shift as NormalShift).end_time || (shift as DefaultShift).end_hour;

  if (start === end) return 0;
  const breakTime = (shift.break_time || 0) / MIN_IN_HOUR;

  return (
    dateUtils.getTimeDuration(
      (shift as NormalShift).start_time || (shift as DefaultShift).start_hour,
      (shift as NormalShift).end_time || (shift as DefaultShift).end_hour,
    ) - breakTime
  );
};

export const getFullTimeString = function getFullTimeString(time: string) {
  const result = time.split(':').map((val) => {
    if (val.length === 1 && Number(val)) return `0${val}`;
    if (val.length > 2) return val.slice(0, 2);
    return val;
  });
  if (result.length <= 2) result.push('00');
  return result.join(':');
};

const getShiftIntervalDateTime = (date: string, start: string, end: string) => {
  const startTime = `${date} ${getFullTimeString(start)}`;
  let endTime = `${date} ${getFullTimeString(end)}`;
  if (dateUtils.isEndNextDay(startTime, endTime)) {
    endTime = dateUtils.setDate(endTime, { addDays: 1 }, 'YYYY-MM-DD HH:mm:ss');
  }

  return { startTime, endTime };
};

export const getClosingTime = (shifts: Shift[]) => {
  const defaultHour = '00:00:00';

  if (!shifts.length) {
    return defaultHour;
  }

  const date = dateUtils.now();

  let maxEndTime = `${date} ${shifts[0].end_hour}`;

  shifts
    .filter((shift) => shift.end_hour !== shift.start_hour)
    .forEach((shift) => {
      const { endTime } = getShiftIntervalDateTime(date, shift.start_hour, shift.end_hour);

      if (maxEndTime < endTime) {
        maxEndTime = endTime;
      }
    });

  return maxEndTime.split(' ')[1];
};

export default {
  getShiftsForSelect,
  sortShifts,
  getWeekShiftNamesCount,
  getShiftWorkingHours,
  getClosingTime,
  getShiftIntervalDateTime,
};
